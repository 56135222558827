<template>
  <div>
    <router-view></router-view>
    <!--<div class="floatingActionButtonContainer">
      <theme-switcher></theme-switcher>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "App",
}
</script>

<style scoped>

</style>